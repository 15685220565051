<template>
  <v-app>
    <v-app-bar
      app
      color="colorPtBlue"
      dark
    >
      <v-spacer></v-spacer>

      <v-btn
        href="https://www.providertrust.com/"
        target="_blank"
        text
      >
        <span class="mr-2">Website</span>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  components: {
    HelloWorld,
  },

  data: () => ({
  }),
};
</script>
<style lang="scss">
</style>